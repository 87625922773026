
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as blacklistxdJwyfVAVhMeta } from "/app/src/pages/cashtec/blacklist.vue?macro=true";
import { default as bonusAtMDAbBsc7Meta } from "/app/src/pages/cashtec/buyout/[id]/bonus.vue?macro=true";
import { default as communication9JXyUfxvQIMeta } from "/app/src/pages/cashtec/buyout/[id]/communication.vue?macro=true";
import { default as customerOcQxK9pSyHMeta } from "/app/src/pages/cashtec/buyout/[id]/customer.vue?macro=true";
import { default as detailINi7kCFsRBMeta } from "/app/src/pages/cashtec/buyout/[id]/detail.vue?macro=true";
import { default as log7BY2NfWfRkMeta } from "/app/src/pages/cashtec/buyout/[id]/log.vue?macro=true";
import { default as summarymjdYaUwLnTMeta } from "/app/src/pages/cashtec/buyout/[id]/summary.vue?macro=true";
import { default as _91id_93GX4p05awrFMeta } from "/app/src/pages/cashtec/buyout/[id].vue?macro=true";
import { default as chatswGIVIrIWgGMeta } from "/app/src/pages/cashtec/chats.vue?macro=true";
import { default as delivery_45notesKnVtfHCFywMeta } from "/app/src/pages/cashtec/delivery-notes.vue?macro=true";
import { default as export0R6JbQsDF8Meta } from "/app/src/pages/cashtec/export.vue?macro=true";
import { default as indexS8cM4oJqtjMeta } from "/app/src/pages/cashtec/index.vue?macro=true";
import { default as logsUURkEyYi0mMeta } from "/app/src/pages/cashtec/logs.vue?macro=true";
import { default as paymentsRw4v5RwWXlMeta } from "/app/src/pages/cashtec/payments.vue?macro=true";
import { default as bonusRQ2A3UrwRHMeta } from "/app/src/pages/cashtec/setup/bonus.vue?macro=true";
import { default as setupaWISAkzWeeMeta } from "/app/src/pages/cashtec/setup.vue?macro=true";
import { default as tools7QtQsJQBUiMeta } from "/app/src/pages/cashtec/tools.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
import { default as business_45case_45invoicesuIHamGl7sfMeta } from "/app/src/pages/upgrade/business-case-invoices.vue?macro=true";
import { default as communicationJ68uTgIXb5Meta } from "/app/src/pages/upgrade/business-case-issue/[id]/communication.vue?macro=true";
import { default as detailnU6K2too9pMeta } from "/app/src/pages/upgrade/business-case-issue/[id]/detail.vue?macro=true";
import { default as historyiYU8N4wUG3Meta } from "/app/src/pages/upgrade/business-case-issue/[id]/history.vue?macro=true";
import { default as _91id_93ec2tjHleAXMeta } from "/app/src/pages/upgrade/business-case-issue/[id].vue?macro=true";
import { default as business_45case_45issuesXLczGYrGdwMeta } from "/app/src/pages/upgrade/business-case-issues.vue?macro=true";
import { default as communicationv6BUOjYgKHMeta } from "/app/src/pages/upgrade/business-case/[id]/communication.vue?macro=true";
import { default as curvesLEDr9ZADWKMeta } from "/app/src/pages/upgrade/business-case/[id]/curves.vue?macro=true";
import { default as customeriyFfeGLSAvMeta } from "/app/src/pages/upgrade/business-case/[id]/customer.vue?macro=true";
import { default as detailyQFayQRodWMeta } from "/app/src/pages/upgrade/business-case/[id]/detail.vue?macro=true";
import { default as historyNfnnghCszFMeta } from "/app/src/pages/upgrade/business-case/[id]/history.vue?macro=true";
import { default as issues8hUpcwusl6Meta } from "/app/src/pages/upgrade/business-case/[id]/issues.vue?macro=true";
import { default as _91id_93IFfqtTWLtMMeta } from "/app/src/pages/upgrade/business-case/[id].vue?macro=true";
import { default as business_45casesqjSMmzJpEbMeta } from "/app/src/pages/upgrade/business-cases.vue?macro=true";
import { default as index95USmLm0yRMeta } from "/app/src/pages/upgrade/index.vue?macro=true";
import { default as installments_45variantsE7jtq96nsjMeta } from "/app/src/pages/upgrade/installments-variants.vue?macro=true";
import { default as program_45master_45devicesEQCpaLd47qMeta } from "/app/src/pages/upgrade/program-master-devices.vue?macro=true";
import { default as stock_45movesgbP8sKNVw3Meta } from "/app/src/pages/upgrade/stock-moves.vue?macro=true";
export default [
  {
    name: "cashtec-blacklist___cs",
    path: "/cashtec/blacklist",
    meta: blacklistxdJwyfVAVhMeta || {},
    component: () => import("/app/src/pages/cashtec/blacklist.vue")
  },
  {
    name: "cashtec-buyout-id___cs",
    path: "/cashtec/buyout/:id()",
    component: () => import("/app/src/pages/cashtec/buyout/[id].vue"),
    children: [
  {
    name: "cashtec-buyout-id-bonus___cs",
    path: "/cashtec/vykup/:id()/bonus",
    meta: bonusAtMDAbBsc7Meta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/bonus.vue")
  },
  {
    name: "cashtec-buyout-id-communication___cs",
    path: "/cashtec/vykup/:id()/komunikace",
    meta: communication9JXyUfxvQIMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/communication.vue")
  },
  {
    name: "cashtec-buyout-id-customer___cs",
    path: "/cashtec/vykup/:id()/zakaznik",
    meta: customerOcQxK9pSyHMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/customer.vue")
  },
  {
    name: "cashtec-buyout-id-detail___cs",
    path: "/cashtec/vykup/:id()/detail",
    meta: detailINi7kCFsRBMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/detail.vue")
  },
  {
    name: "cashtec-buyout-id-log___cs",
    path: "/cashtec/vykup/:id()/log",
    meta: log7BY2NfWfRkMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/log.vue")
  },
  {
    name: "cashtec-buyout-id-summary___cs",
    path: "/cashtec/vykup/:id()/sumarizace",
    meta: summarymjdYaUwLnTMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/summary.vue")
  }
]
  },
  {
    name: "cashtec-chats___cs",
    path: "/cashtec/chaty",
    meta: chatswGIVIrIWgGMeta || {},
    component: () => import("/app/src/pages/cashtec/chats.vue")
  },
  {
    name: "cashtec-delivery-notes___cs",
    path: "/cashtec/dodaci-listy",
    meta: delivery_45notesKnVtfHCFywMeta || {},
    component: () => import("/app/src/pages/cashtec/delivery-notes.vue")
  },
  {
    name: "cashtec-export___cs",
    path: "/cashtec/export",
    meta: export0R6JbQsDF8Meta || {},
    component: () => import("/app/src/pages/cashtec/export.vue")
  },
  {
    name: "cashtec___cs",
    path: "/cashtec",
    meta: indexS8cM4oJqtjMeta || {},
    component: () => import("/app/src/pages/cashtec/index.vue")
  },
  {
    name: "cashtec-logs___cs",
    path: "/cashtec/logy",
    meta: logsUURkEyYi0mMeta || {},
    component: () => import("/app/src/pages/cashtec/logs.vue")
  },
  {
    name: "cashtec-payments___cs",
    path: "/cashtec/platby",
    meta: paymentsRw4v5RwWXlMeta || {},
    component: () => import("/app/src/pages/cashtec/payments.vue")
  },
  {
    name: "cashtec-setup___cs",
    path: "/cashtec/setup",
    component: () => import("/app/src/pages/cashtec/setup.vue"),
    children: [
  {
    name: "cashtec-setup-bonus___cs",
    path: "/cashtec/nastaveni/bonus",
    meta: bonusRQ2A3UrwRHMeta || {},
    component: () => import("/app/src/pages/cashtec/setup/bonus.vue")
  }
]
  },
  {
    name: "cashtec-tools___cs",
    path: "/cashtec/nastroje",
    meta: tools7QtQsJQBUiMeta || {},
    component: () => import("/app/src/pages/cashtec/tools.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue")
  },
  {
    name: "profile___cs",
    path: "/profil",
    meta: profileTx35vCKuH1Meta || {},
    component: () => import("/app/src/pages/profile.vue")
  },
  {
    name: "upgrade-business-case-invoices___cs",
    path: "/upgrade/obchodni-pripady-faktury",
    meta: business_45case_45invoicesuIHamGl7sfMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case-invoices.vue")
  },
  {
    name: "upgrade-business-case-issue-id___cs",
    path: "/upgrade/business-case-issue/:id()",
    component: () => import("/app/src/pages/upgrade/business-case-issue/[id].vue"),
    children: [
  {
    name: "upgrade-business-case-issue-id-communication___cs",
    path: "/upgrade/obchodni-pripady-problem/:id()/komunikace",
    meta: communicationJ68uTgIXb5Meta || {},
    component: () => import("/app/src/pages/upgrade/business-case-issue/[id]/communication.vue")
  },
  {
    name: "upgrade-business-case-issue-id-detail___cs",
    path: "/upgrade/obchodni-pripady-problem/:id()/detail",
    meta: detailnU6K2too9pMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case-issue/[id]/detail.vue")
  },
  {
    name: "upgrade-business-case-issue-id-history___cs",
    path: "/upgrade/obchodni-pripady-problem/:id()/historie",
    meta: historyiYU8N4wUG3Meta || {},
    component: () => import("/app/src/pages/upgrade/business-case-issue/[id]/history.vue")
  }
]
  },
  {
    name: "upgrade-business-case-issues___cs",
    path: "/upgrade/obchodni-pripady-problemy",
    meta: business_45case_45issuesXLczGYrGdwMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case-issues.vue")
  },
  {
    name: "upgrade-business-case-id___cs",
    path: "/upgrade/business-case/:id()",
    component: () => import("/app/src/pages/upgrade/business-case/[id].vue"),
    children: [
  {
    name: "upgrade-business-case-id-communication___cs",
    path: "/upgrade/obchodni-pripady/:id()/komunikace",
    meta: communicationv6BUOjYgKHMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case/[id]/communication.vue")
  },
  {
    name: "upgrade-business-case-id-curves___cs",
    path: "/upgrade/obchodni-pripad/:id()/krivky",
    meta: curvesLEDr9ZADWKMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case/[id]/curves.vue")
  },
  {
    name: "upgrade-business-case-id-customer___cs",
    path: "/upgrade/obchodni-pripady/:id()/zakaznik",
    meta: customeriyFfeGLSAvMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case/[id]/customer.vue")
  },
  {
    name: "upgrade-business-case-id-detail___cs",
    path: "/upgrade/obchodni-pripad/:id()/detail",
    meta: detailyQFayQRodWMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case/[id]/detail.vue")
  },
  {
    name: "upgrade-business-case-id-history___cs",
    path: "/upgrade/obchodni-pripad/:id()/historie",
    meta: historyNfnnghCszFMeta || {},
    component: () => import("/app/src/pages/upgrade/business-case/[id]/history.vue")
  },
  {
    name: "upgrade-business-case-id-issues___cs",
    path: "/upgrade/obchodni-pripad/:id()/problemy",
    meta: issues8hUpcwusl6Meta || {},
    component: () => import("/app/src/pages/upgrade/business-case/[id]/issues.vue")
  }
]
  },
  {
    name: "upgrade-business-cases___cs",
    path: "/upgrade/obchodni-pripady",
    meta: business_45casesqjSMmzJpEbMeta || {},
    component: () => import("/app/src/pages/upgrade/business-cases.vue")
  },
  {
    name: "upgrade___cs",
    path: "/upgrade",
    meta: index95USmLm0yRMeta || {},
    component: () => import("/app/src/pages/upgrade/index.vue")
  },
  {
    name: "upgrade-installments-variants___cs",
    path: "/upgrade/vykupni-krivky",
    meta: installments_45variantsE7jtq96nsjMeta || {},
    component: () => import("/app/src/pages/upgrade/installments-variants.vue")
  },
  {
    name: "upgrade-program-master-devices___cs",
    path: "/upgrade/zarizeni-v-programu",
    meta: program_45master_45devicesEQCpaLd47qMeta || {},
    component: () => import("/app/src/pages/upgrade/program-master-devices.vue")
  },
  {
    name: "upgrade-stock-moves___cs",
    path: "/upgrade/skladove-pohyby",
    meta: stock_45movesgbP8sKNVw3Meta || {},
    component: () => import("/app/src/pages/upgrade/stock-moves.vue")
  }
]